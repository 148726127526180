(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/actions/mark-latest.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/actions/mark-latest.js');
'use strict';

const {
  actions
} = svs.components.chat.chatData;
const markLatest = roomId => (dispatch, getState) => {
  const chatState = getState().Chat;
  const messages = chatState.messages[roomId] || [];
  if (messages.length) {
    dispatch(actions.markRead(messages.slice(-1).pop().id, roomId));
  }
};
setGlobal('svs.components.chat.chatData.actions', {
  markLatest
});

 })(window);