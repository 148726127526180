(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/reducers/chat-messages-reducer.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/reducers/chat-messages-reducer.js');

'use strict';

function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  CHAT_PAGE_LOAD_SUCCESS,
  CHAT_RECEIVE_MESSAGE,
  CHAT_PURGE_ROOM
} = svs.components.chat.chatData.actions;
const chatMessagesReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let action = arguments.length > 1 ? arguments[1] : undefined;
  const roomId = action.roomId;
  switch (action.type) {
    case CHAT_PAGE_LOAD_SUCCESS:
      {
        const stateMsgs = state[roomId] || [];
        return action.reset ? _objectSpread(_objectSpread({}, state), {}, {
          [roomId]: action.messages
        }) : _objectSpread(_objectSpread({}, state), {}, {
          [roomId]: action.messages.filter(_ref => {
            let {
              id
            } = _ref;
            return stateMsgs.findIndex(m => m.id === id) === -1;
          }).concat(stateMsgs)
        });
      }
    case CHAT_RECEIVE_MESSAGE:
      {
        const messages = state[roomId] || [];
        const message = messages.find(message => message.id === action.messageData.id);
        const index = message ? messages.indexOf(message) : messages.length;
        return _objectSpread(_objectSpread({}, state), {}, {
          [roomId]: [...messages.slice(0, index), action.messageData, ...messages.slice(index + 1)]
        });
      }
    case CHAT_PURGE_ROOM:
      {
        const _ref2 = "".concat(action.roomId),
          {
            [_ref2]: deleteMe
          } = state,
          saveUs = _objectWithoutProperties(state, [_ref2].map(_toPropertyKey));
        return saveUs;
      }
    default:
      return state;
  }
};
setGlobal('svs.components.chat.chatData.reducers.chatMessagesReducer', chatMessagesReducer);

 })(window);