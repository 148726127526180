(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/actions/toggle.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/actions/toggle.js');
'use strict';

const {
  actions
} = svs.components.chat.chatData;
const toggleChat = roomId => (dispatch, getState) => {
  if (getState().Chat.isOpen) {
    dispatch(actions.closeChat(roomId));
  } else {
    dispatch(actions.openChat(roomId));
  }
};
setGlobal('svs.components.chat.chatData.actions', {
  toggleChat
});

 })(window);