(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/reducers/chat-reducer.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/reducers/chat-reducer.js');

'use strict';

const {
  CHAT_INITIALIZE_COMPONENT_STATE
} = svs.components.chat.chatData.actions;
const {
  chatRoomsReducer,
  chatMessagesReducer,
  chatQueuedMessagesReducer,
  chatMetaReducer,
  chatBlockedReducer,
  chatIsOpenReducer
} = svs.components.chat.chatData.reducers;
const chatReducer = RTK.combineReducers({
  isOpen: chatIsOpenReducer,
  customerId: function () {
    let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    let action = arguments.length > 1 ? arguments[1] : undefined;
    return action.type === CHAT_INITIALIZE_COMPONENT_STATE ? action.customerId : state;
  },
  queuedMessages: chatQueuedMessagesReducer,
  rooms: chatRoomsReducer,
  meta: chatMetaReducer,
  messages: chatMessagesReducer,
  blockedUsers: chatBlockedReducer
});
setGlobal('svs.components.chat.chatData.reducers.chatReducer', chatReducer);

 })(window);