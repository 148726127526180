(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/services/mark-read.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/services/mark-read.js');

'use strict';

const {
  jupiter
} = svs.core;
const {
  constants
} = svs.components.chat.chatData;
let cooldown = false;
let queuedCall;
const markRead = (messageId, roomId, callback, bustTimeout) => {
  if (cooldown && !bustTimeout) {
    queuedCall = [messageId, roomId, callback];
  } else {
    jupiter.put({
      path: "/chat/1/rooms/".concat(roomId, "/messages/latestread"),
      data: {
        messageId
      }
    }, () => callback(), err => callback(new Error(err)));
    if (!bustTimeout) {
      cooldown = true;
      setTimeout(() => {
        cooldown = false;
        if (queuedCall) {
          markRead(...queuedCall);
          queuedCall = null;
        }
      }, constants.MARK_READ_COOLDOWN);
    }
  }
};
setGlobal('svs.components.chat.chatData.services.markRead', markRead);

 })(window);