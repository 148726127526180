(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/helpers/get-messagelist-for-room.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/helpers/get-messagelist-for-room.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  helpers
} = svs.components.chat.chatData;
function getMessageListForRoom(roomId, chatState) {
  const messages = chatState.messages[roomId] || [];
  const queuedMessages = chatState.queuedMessages[roomId] || [];
  const meta = chatState.meta[roomId] || {};
  return messages.concat(Object.keys(queuedMessages).sort((a, b) => queuedMessages[a].localId - queuedMessages[b].localId).map(key => queuedMessages[key])).map(msg => _objectSpread(_objectSpread({}, msg), {}, {
    status: helpers.getMessageStatus(messages.find(m => m.id === msg.id), queuedMessages[msg.id || msg.localId], meta[msg.id])
  }));
}
setGlobal('svs.components.chat.chatData.helpers.getMessageListForRoom', getMessageListForRoom);

 })(window);