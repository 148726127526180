(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/reducers/chat-blocked-reducer.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/reducers/chat-blocked-reducer.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  CHAT_GET_BLOCKED_USERS_SUCCESS,
  CHAT_UNBLOCK_USER_SUCCESS
} = svs.components.chat.chatData.actions;
const chatBlockedReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let action = arguments.length > 1 ? arguments[1] : undefined;
  const roomId = action.roomId;
  switch (action.type) {
    case CHAT_GET_BLOCKED_USERS_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          [roomId]: action.data.blockedUsers.blockedUsers
        });
      }
    case CHAT_UNBLOCK_USER_SUCCESS:
      {
        const blockedUsers = state[roomId] ? state[roomId] : [];
        const blockedUser = blockedUsers.find(user => user.UserIdentity.userId === action.customerId);
        const index = blockedUser ? blockedUsers.indexOf(blockedUser) : blockedUsers.length;
        return _objectSpread(_objectSpread({}, state), {}, {
          [roomId]: [...blockedUsers.slice(0, index), ...blockedUsers.slice(index + 1)]
        });
      }
    default:
      return state;
  }
};
setGlobal('svs.components.chat.chatData.reducers.chatBlockedReducer', chatBlockedReducer);

 })(window);