(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/actions/open.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/actions/open.js');
'use strict';

const CHAT_OPEN = 'CHAT/CHAT_OPEN';
const {
  actions
} = svs.components.chat.chatData;
const openChat = roomId => dispatch => {
  dispatch({
    type: CHAT_OPEN
  });
  if (roomId) {
    dispatch(actions.markLatest(roomId));
  }
};
setGlobal('svs.components.chat.chatData.actions', {
  CHAT_OPEN,
  openChat
});

 })(window);