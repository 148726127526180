(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/actions/get-unread.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/actions/get-unread.js');
'use strict';

const {
  services
} = svs.components.chat.chatData;
const CHAT_GET_UNREAD_INIT = 'CHAT/CHAT_GET_UNREAD_INIT';
const CHAT_GET_UNREAD_SUCCESS = 'CHAT/CHAT_GET_UNREAD_SUCCESS';
const CHAT_GET_UNREAD_FAILURE = 'CHAT/CHAT_GET_UNREAD_FAILURE';
const getUnread = () => dispatch => {
  dispatch({
    type: CHAT_GET_UNREAD_INIT
  });
  services.getUnread((error, result) => {
    if (!error) {
      dispatch({
        type: CHAT_GET_UNREAD_SUCCESS,
        data: result
      });
    } else {
      dispatch({
        type: CHAT_GET_UNREAD_FAILURE,
        error
      });
    }
  });
};
setGlobal('svs.components.chat.chatData.actions', {
  CHAT_GET_UNREAD_INIT,
  CHAT_GET_UNREAD_FAILURE,
  CHAT_GET_UNREAD_SUCCESS,
  getUnread
});

 })(window);