(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/actions/close.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/actions/close.js');
'use strict';

const CHAT_CLOSE = 'CHAT/CHAT_CLOSE';
const closeChat = () => dispatch => {
  dispatch({
    type: CHAT_CLOSE
  });
};
setGlobal('svs.components.chat.chatData.actions', {
  CHAT_CLOSE,
  closeChat
});

 })(window);