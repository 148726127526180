(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/prescript.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/prescript.js');

'use strict';

setGlobal('svs.components.chat.chatData.actions', {});
setGlobal('svs.components.chat.chatData.services', {});
setGlobal(
  'svs.components.chat.chatData.log',
  svs.core.log.getLogger('chat:data')
);


 })(window);