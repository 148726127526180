(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/services/delete-message.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/services/delete-message.js');

'use strict';

const {
  jupiter
} = svs.core;
const deleteMessage = (messageId, callback) => {
  jupiter.delete("/chat/1/messages/".concat(messageId), () => callback(), () => callback(new Error('Det gick inte att ta bort meddelandet. Försök igen.')));
};
setGlobal('svs.components.chat.chatData.services.deleteMessage', deleteMessage);

 })(window);