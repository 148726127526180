(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/actions/send-message.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/actions/send-message.js');
'use strict';

const {
  services,
  helpers,
  log
} = svs.components.chat.chatData;
const CHAT_SEND_MESSAGE_INIT = 'CHAT/CHAT_SEND_MESSAGE_INIT';
const CHAT_SEND_MESSAGE_SUCCESS = 'CHAT/CHAT_SEND_MESSAGE_SUCCESS';
const CHAT_SEND_MESSAGE_FAILURE = 'CHAT/CHAT_SEND_MESSAGE_FAILURE';
const sendMessageToRoom = (message, roomId) => dispatch => {
  const {
    firstName,
    lastName
  } = svs.core.session.data.userSession;
  const name = "".concat(firstName, " ").concat(lastName);
  const localId = helpers.generateLocalId();
  dispatch({
    type: CHAT_SEND_MESSAGE_INIT,
    message: message.trim(),
    roomId,
    localId,
    name
  });
  log.debug('Send message');
  services.sendMessageToRoom(message.trim(), localId, roomId, (error, result) => {
    log.debug("Send message done. Success: ".concat(!error, " messageId: ").concat((result || {}).id));
    if (error) {
      dispatch({
        type: CHAT_SEND_MESSAGE_FAILURE,
        error,
        roomId,
        localId
      });
    } else {
      const {
        createdAt,
        id
      } = result;
      dispatch({
        type: CHAT_SEND_MESSAGE_SUCCESS,
        messageId: id,
        localId,
        createdAt,
        roomId
      });
    }
  });
};
setGlobal('svs.components.chat.chatData.actions', {
  CHAT_SEND_MESSAGE_INIT,
  CHAT_SEND_MESSAGE_SUCCESS,
  CHAT_SEND_MESSAGE_FAILURE,
  sendMessageToRoom
});

 })(window);