(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/services/update-user.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/services/update-user.js');

'use strict';

const {
  jupiter
} = svs.core;
const updateUser = (roomId, customerId, shouldBlock, callback) => {
  jupiter.put({
    path: "/chat/1/rooms/".concat(roomId, "/members/status"),
    data: {
      UserId: customerId,
      MemberStatus: shouldBlock ? 'Blocked' : 'Active'
    }
  }, () => callback(), error => callback(new Error('Det gick inte att blockera användaren.', error)));
};
setGlobal('svs.components.chat.chatData.services.updateUser', updateUser);

 })(window);