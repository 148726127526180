(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/services/get-chat-room.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/services/get-chat-room.js');

'use strict';

const {
  jupiter
} = svs.core;
const getChatRoom = (roomId, callback) => {
  jupiter.get("/chat/1/rooms/".concat(roomId), res => {
    callback(undefined, res.chatRoom);
  }, err => callback(new Error(err)));
};
setGlobal('svs.components.chat.chatData.services.getChatRoom', getChatRoom);

 })(window);