(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/helpers/get-blocked-users-from-room.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/helpers/get-blocked-users-from-room.js');

'use strict';

function getBlockedUsersFromRoom(roomId, chatState) {
  return chatState.Chat.blockedUsers[roomId] ? chatState.Chat.blockedUsers[roomId].map(userInfo => ({
    customerName: userInfo.UserName,
    blockedByName: userInfo.BlockedByName,
    userId: userInfo.UserIdentity.userId
  })) : [];
}
setGlobal('svs.components.chat.chatData.helpers.getBlockedUsersFromRoom', getBlockedUsersFromRoom);

 })(window);