(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/actions/page-load.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/actions/page-load.js');
'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  services
} = svs.components.chat.chatData;
const CHAT_PAGE_LOAD_INIT = 'CHAT/CHAT_PAGE_LOAD_INIT';
const CHAT_PAGE_LOAD_SUCCESS = 'CHAT/CHAT_PAGE_LOAD_SUCCESS';
const CHAT_PAGE_LOAD_FAILURE = 'CHAT/CHAT_PAGE_LOAD_FAILURE';
const CHAT_PAGE_LOAD_CLOSED = 'CHAT/CHAT_PAGE_LOAD_CLOSED';
const {
  constants
} = svs.components.chat.chatData;
const fetchMessagesByRoomId = function (roomId) {
  let {
    reset = false,
    fetchRoom = true
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return (dispatch, getState) => {
    const chatState = getState().Chat;
    if ((chatState.rooms[roomId] || {}).loading) return;
    const finalCallback = (err, results) => {
      if (err) {
        dispatch({
          type: CHAT_PAGE_LOAD_FAILURE,
          error: 'Det gick inte att initiera chatten',
          roomId
        });
      } else {
        const [result1, result2] = results;
        dispatch(_objectSpread(_objectSpread({
          type: CHAT_PAGE_LOAD_SUCCESS,
          roomId
        }, result1), {}, {
          room: result2,
          reset
        }));
      }
    };
    dispatch({
      type: CHAT_PAGE_LOAD_INIT,
      roomId
    });
    const oldestMessageId = !reset ? (chatState.messages[roomId] || [{}])[0].id : undefined;
    async.waterfall([callback => {
      if (fetchRoom) {
        services.getChatRoom(roomId, callback);
      } else {
        callback(null, {
          name: '',
          status: 'Open'
        });
      }
    }, (response, callback) => {
      if (response.status === constants.ROOM_STATUS_CLOSED && response.isCurrentChatRoomMemberBlocked) {
        return finalCallback(undefined, [{}, response]);
      }
      services.fetchMessages(roomId, oldestMessageId, (err, data) => {
        if (err && err.status === 403) {
          return dispatch({
            type: CHAT_PAGE_LOAD_CLOSED,
            roomId
          });
        }
        callback(err, [data, response]);
      });
    }], finalCallback);
  };
};
setGlobal('svs.components.chat.chatData.actions', {
  CHAT_PAGE_LOAD_INIT,
  CHAT_PAGE_LOAD_SUCCESS,
  CHAT_PAGE_LOAD_FAILURE,
  CHAT_PAGE_LOAD_CLOSED,
  fetchMessagesByRoomId
});

 })(window);