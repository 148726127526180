(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/services/fetch-messages.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/services/fetch-messages.js');

'use strict';

const {
  jupiter
} = svs.core;
const {
  constants,
  helpers
} = svs.components.chat.chatData;
const standardErrorMsg = 'Det gick inte hämta meddelanden.';
const fetchMessages = (roomId, oldestMessageId, callback) => {
  jupiter.get("/chat/1/rooms/".concat(roomId, "/messages?count=").concat(constants.DEFAULT_FETCH_COUNT).concat(oldestMessageId ? "&messageId=".concat(oldestMessageId, "&fetchNewer=false") : ''), res => callback(undefined, {
    hasLoadedEverything: res.result.messages.length < constants.DEFAULT_FETCH_COUNT,
    messages: res.result.messages.map(message => helpers.formatMessage(message))
  }), err => {
    if (err.status === 403) {
      return callback(err);
    }
    callback(new Error(standardErrorMsg));
  });
};
setGlobal('svs.components.chat.chatData.services.fetchMessages', fetchMessages);

 })(window);