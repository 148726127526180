(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/services/get-unread-by-room.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/services/get-unread-by-room.js');

'use strict';

const {
  jupiter
} = svs.core;
const getUnreadByRoom = (roomId, callback) => {
  jupiter.get("/chat/1/rooms/".concat(roomId, "/messages/unread"), res => {
    callback(undefined, {
      [roomId]: {
        unread: res.result.unreadMessages
      }
    });
  }, err => callback(new Error(err)));
};
setGlobal('svs.components.chat.chatData.services.getUnreadByRoom', getUnreadByRoom);

 })(window);