(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/actions/get-unread-by-room.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/actions/get-unread-by-room.js');
'use strict';

const {
  services
} = svs.components.chat.chatData;
const CHAT_GET_UNREAD_BY_ROOM_INIT = 'CHAT/CHAT_GET_UNREAD_BY_ROOM_INIT';
const CHAT_GET_UNREAD_BY_ROOM_SUCCESS = 'CHAT/CHAT_GET_UNREAD_BY_ROOM_SUCCESS';
const CHAT_GET_UNREAD_BY_ROOM_FAILURE = 'CHAT/CHAT_GET_UNREAD_BY_ROOM_FAILURE';
const getUnreadByRoom = roomId => dispatch => {
  dispatch({
    type: CHAT_GET_UNREAD_BY_ROOM_INIT
  });
  services.getUnreadByRoom(roomId, (error, result) => {
    if (!error) {
      dispatch({
        type: CHAT_GET_UNREAD_BY_ROOM_SUCCESS,
        data: result,
        roomId
      });
    } else {
      dispatch({
        type: CHAT_GET_UNREAD_BY_ROOM_FAILURE,
        error
      });
    }
  });
};
setGlobal('svs.components.chat.chatData.actions', {
  CHAT_GET_UNREAD_BY_ROOM_INIT,
  CHAT_GET_UNREAD_BY_ROOM_FAILURE,
  CHAT_GET_UNREAD_BY_ROOM_SUCCESS,
  getUnreadByRoom
});

 })(window);