(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/actions/blocked-users.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/actions/blocked-users.js');
'use strict';

const {
  services
} = svs.components.chat.chatData;
const CHAT_GET_BLOCKED_USERS_SUCCESS = 'CHAT/CHAT_GET_BLOCKED_USERS_SUCCESS';
const CHAT_GET_BLOCKED_USERS_FAILURE = 'CHAT/CHAT_GET_BLOCKED_USERS_FAILURE';
const CHAT_BLOCK_USER_SUCCESS = 'CHAT/CHAT_BLOCK_USER_SUCCESS';
const CHAT_BLOCK_USER_FAILURE = 'CHAT/CHAT_BLOCK_USER_FAILURE';
const CHAT_UNBLOCK_USER_SUCCESS = 'CHAT/CHAT_UNBLOCK_USER_SUCCESS';
const CHAT_UNBLOCK_USER_FAILURE = 'CHAT/CHAT_UNBLOCK_USER_FAILURE';
const getBlockedUsers = roomId => dispatch => {
  services.fetchBlockedUsers(roomId, (error, result) => {
    if (!error) {
      dispatch({
        type: CHAT_GET_BLOCKED_USERS_SUCCESS,
        data: result,
        roomId
      });
    } else {
      dispatch({
        type: CHAT_GET_BLOCKED_USERS_FAILURE,
        error
      });
    }
  });
};
const unblockUser = (roomId, customerId) => dispatch => {
  services.updateUser(roomId, customerId, false, error => {
    if (error) {
      dispatch({
        type: CHAT_UNBLOCK_USER_FAILURE,
        error
      });
    } else {
      dispatch({
        type: CHAT_UNBLOCK_USER_SUCCESS,
        roomId,
        customerId
      });
      dispatch(svs.components.chat.chatData.actions.fetchMessagesByRoomId(roomId, {
        reset: true,
        fetchRoom: true
      }));
    }
  });
};
const blockUser = (roomId, customerId) => dispatch => {
  services.updateUser(roomId, customerId, true, error => {
    if (error) {
      dispatch({
        type: CHAT_BLOCK_USER_FAILURE,
        error
      });
    } else {
      dispatch({
        type: CHAT_BLOCK_USER_SUCCESS,
        roomId,
        customerId
      });
      dispatch(svs.components.chat.chatData.actions.fetchMessagesByRoomId(roomId, {
        reset: true,
        fetchRoom: true
      }));
    }
  });
};
setGlobal('svs.components.chat.chatData.actions', {
  getBlockedUsers,
  blockUser,
  unblockUser,
  CHAT_GET_BLOCKED_USERS_SUCCESS,
  CHAT_BLOCK_USER_SUCCESS,
  CHAT_UNBLOCK_USER_SUCCESS
});

 })(window);