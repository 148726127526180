(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/helpers/validate-chat-message.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/helpers/validate-chat-message.js');

'use strict';

const {
  MAX_CHARACTER_COUNT
} = svs.components.chat.chatData.constants;
function validateChatMessage(message) {
  let maxLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : MAX_CHARACTER_COUNT;
  const trimmedMessage = (message || '').trim();
  if (trimmedMessage.length > maxLength) {
    return new Error("Meddelande f\xE5r ej vara l\xE4ngre \xE4n ".concat(maxLength, " tecken"));
  }
}
setGlobal('svs.components.chat.chatData.helpers.validateChatMessage', validateChatMessage);

 })(window);