(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/reducers/chat-is-open-reducer.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/reducers/chat-is-open-reducer.js');

'use strict';

const {
  CHAT_CLOSE,
  CHAT_OPEN
} = svs.components.chat.chatData.actions;
const chatIsOpenReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case CHAT_CLOSE:
      {
        return false;
      }
    case CHAT_OPEN:
      {
        return true;
      }
    default:
      return state;
  }
};
setGlobal('svs.components.chat.chatData.reducers.chatIsOpenReducer', chatIsOpenReducer);

 })(window);