(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/services/update-chat-room.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/services/update-chat-room.js');

'use strict';

const {
  jupiter
} = svs.core;
const updateChatRoom = (roomId, data, callback) => {
  jupiter.put({
    path: "/chat/1/rooms/".concat(roomId),
    data
  }, () => callback(undefined), err => callback(err));
};
setGlobal('svs.components.chat.chatData.services.updateChatRoom', updateChatRoom);

 })(window);