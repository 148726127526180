(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/actions/toggle-status.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/actions/toggle-status.js');
'use strict';

const {
  services,
  constants
} = svs.components.chat.chatData;
const CHAT_TOGGLE_STATUS_INIT = 'CHAT/TOGGLE_STATUS_INIT';
const CHAT_TOGGLE_STATUS_SUCCESS = 'CHAT/TOGGLE_STATUS_SUCCESS';
const CHAT_TOGGLE_STATUS_FAILURE = 'CHAT/TOGGLE_STATUS_FAILURE';
const toggleStatus = roomId => (dispatch, getState) => {
  dispatch({
    type: CHAT_TOGGLE_STATUS_INIT,
    roomId
  });
  const room = getState().Chat.rooms[roomId];
  const newStatus = room.status === constants.ROOM_STATUS_OPEN ? constants.ROOM_STATUS_CLOSED : constants.ROOM_STATUS_OPEN;
  services.updateChatRoom(roomId, {
    name: room.name,
    status: newStatus
  }, error => {
    if (error) {
      dispatch({
        type: CHAT_TOGGLE_STATUS_FAILURE,
        roomId,
        newStatus
      });
    } else {
      dispatch({
        type: CHAT_TOGGLE_STATUS_SUCCESS,
        roomId,
        newStatus
      });
    }
  });
};
setGlobal('svs.components.chat.chatData.actions', {
  CHAT_TOGGLE_STATUS_INIT,
  CHAT_TOGGLE_STATUS_SUCCESS,
  CHAT_TOGGLE_STATUS_FAILURE,
  toggleStatus
});

 })(window);