(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/services/fetch-blocked-users.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/services/fetch-blocked-users.js');

'use strict';

const {
  jupiter
} = svs.core;
const standardErrorMsg = 'Det gick inte hämta blockerade medlemmar.';
const fetchBlockedUsers = (roomId, callback) => {
  jupiter.get("/chat/1/rooms/".concat(roomId, "/members/blocked"), res => callback(undefined, {
    blockedUsers: res.body
  }), () => callback(new Error(standardErrorMsg)));
};
setGlobal('svs.components.chat.chatData.services.fetchBlockedUsers', fetchBlockedUsers);

 })(window);