(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/actions/mark-read.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/actions/mark-read.js');
'use strict';

const {
  services
} = svs.components.chat.chatData;
const CHAT_MARK_READ_LOCAL = 'CHAT/CHAT_MARK_READ_LOCAL';
const CHAT_MARK_READ_FAILURE = 'CHAT/CHAT_MARK_READ_FAILURE';
const CHAT_MARK_READ_SUCCESS = 'CHAT/CHAT_MARK_READ_SUCCESS';
const markRead = (messageId, roomId) => (dispatch, getState) => {
  const chatState = getState().Chat;
  const auth = getState().Auth;
  const messages = chatState.messages[roomId] || [];
  const room = chatState.rooms[roomId] || {};
  const pos = messages.findIndex(msg => msg.id === messageId);
  const unread = messages.length - 1 - pos;
  if (!auth) return;
  if (pos > 0) {
    dispatch({
      type: CHAT_MARK_READ_LOCAL,
      roomId,
      latestRead: messageId,
      unread
    });
  }
  if (room.latestReadServer !== messageId) {
    services.markRead(messageId, roomId, err => {
      if (err) {
        dispatch({
          type: CHAT_MARK_READ_FAILURE,
          roomId,
          messageId,
          error: err
        });
      } else {
        dispatch({
          type: CHAT_MARK_READ_SUCCESS,
          roomId,
          messageId
        });
      }
    });
  }
};
setGlobal('svs.components.chat.chatData.actions', {
  CHAT_MARK_READ_LOCAL,
  CHAT_MARK_READ_FAILURE,
  CHAT_MARK_READ_SUCCESS,
  markRead
});

 })(window);