(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/reducers/chat-rooms-reducer.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/reducers/chat-rooms-reducer.js');

'use strict';

function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  CHAT_PAGE_LOAD_INIT,
  CHAT_PAGE_LOAD_SUCCESS,
  CHAT_PAGE_LOAD_FAILURE,
  CHAT_PAGE_LOAD_CLOSED,
  CHAT_GET_UNREAD_SUCCESS,
  CHAT_MARK_READ_LOCAL,
  CHAT_MARK_READ_SUCCESS,
  CHAT_RECEIVE_MESSAGE,
  CHAT_GET_UNREAD_BY_ROOM_SUCCESS,
  CHAT_PURGE_ROOM,
  CHAT_TOGGLE_STATUS_SUCCESS
} = svs.components.chat.chatData.actions;
const {
  constants
} = svs.components.chat.chatData;
const chatRoomsReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let action = arguments.length > 1 ? arguments[1] : undefined;
  const roomId = action.roomId;
  switch (action.type) {
    case CHAT_PAGE_LOAD_INIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        [roomId]: _objectSpread(_objectSpread({}, state[roomId]), {}, {
          loading: true,
          error: null,
          status: constants.ROOM_STATUS_OPEN
        })
      });
    case CHAT_PAGE_LOAD_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        [roomId]: _objectSpread(_objectSpread({}, state[roomId]), {}, {
          loading: false,
          error: action.error
        })
      });
    case CHAT_PAGE_LOAD_CLOSED:
      return _objectSpread(_objectSpread({}, state), {}, {
        [roomId]: _objectSpread(_objectSpread({}, state[roomId]), {}, {
          loading: false,
          status: 'Closed'
        })
      });
    case CHAT_PAGE_LOAD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        [roomId]: _objectSpread(_objectSpread({}, state[roomId]), {}, {
          loading: false,
          hasLoadedEverything: action.hasLoadedEverything,
          name: action.room.name,
          isUserBlocked: action.room.isCurrentChatRoomMemberBlocked,
          status: action.room.status
        })
      });
    case CHAT_PURGE_ROOM:
      {
        const _ref = "".concat(action.roomId),
          {
            [_ref]: deleteMe
          } = state,
          saveUs = _objectWithoutProperties(state, [_ref].map(_toPropertyKey));
        return saveUs;
      }
    case CHAT_GET_UNREAD_SUCCESS:
      {
        return Object.keys(action.data).reduce((acc, roomId) => _objectSpread(_objectSpread({}, acc), {}, {
          [roomId]: _objectSpread(_objectSpread({}, state[roomId]), {}, {
            unread: action.data[roomId].unread,
            latestRead: action.data[roomId].latestRead,
            latestReadServer: action.data[roomId].latestRead
          })
        }), state);
      }
    case CHAT_GET_UNREAD_BY_ROOM_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          [roomId]: _objectSpread(_objectSpread({}, state[roomId]), {}, {
            unread: action.data[roomId].unread
          })
        });
      }
    case CHAT_MARK_READ_LOCAL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          [roomId]: _objectSpread(_objectSpread({}, state[roomId]), {}, {
            unread: action.unread,
            latestRead: action.latestRead
          })
        });
      }
    case CHAT_RECEIVE_MESSAGE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          [roomId]: _objectSpread(_objectSpread({}, state[roomId]), {}, {
            unread: ((state[roomId] || {}).unread || 0) + action.increaseUnreadCount
          })
        });
      }
    case CHAT_MARK_READ_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        [roomId]: _objectSpread(_objectSpread({}, state[roomId]), {}, {
          latestReadServer: action.messageId
        })
      });
    case CHAT_TOGGLE_STATUS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        [roomId]: _objectSpread(_objectSpread({}, state[roomId]), {}, {
          status: action.newStatus
        })
      });
    default:
      return state;
  }
};
setGlobal('svs.components.chat.chatData.reducers.chatRoomsReducer', chatRoomsReducer);

 })(window);