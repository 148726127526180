(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/constants.js');

'use strict';

setGlobal('svs.components.chat.chatData.constants', {
  MAX_CHARACTER_COUNT: 250,
  DEFAULT_FETCH_COUNT: 20,
  MARK_READ_COOLDOWN: 1000,
  SERVER_MESSAGE_STATUS_ORIGINAL: 0,
  SERVER_MESSAGE_STATUS_CHANGED: 1,
  SERVER_MESSAGE_STATUS_DELETED: 2,
  USER_STATUS_NORMAL: 0,
  USER_STATUS_FORMER_MEMBER: 1,
  MESSAGE_STATUS_SEND_PENDING: 'CHAT_CONSTANT/MESSAGE_STATUS_SEND_PENDING',
  MESSAGE_STATUS_SEND_FAILED: 'CHAT_CONSTANT/MESSAGE_STATUS_SEND_FAILED',
  MESSAGE_STATUS_SENT: 'CHAT_CONSTANT/MESSAGE_STATUS_SENT',
  MESSAGE_STATUS_EDITED: 'CHAT_CONSTANT/MESSAGE_STATUS_EDITED',
  MESSAGE_STATUS_NORMAL: 'CHAT_CONSTANT/MESSAGE_STATUS_NORMAL',
  MESSAGE_STATUS_DELETE_PENDING: 'CHAT_CONSTANT/MESSAGE_STATUS_DELETE_PENDING',
  MESSAGE_STATUS_DELETE_FAILED: 'CHAT_CONSTANT/MESSAGE_STATUS_DELETE_FAILED',
  MESSAGE_STATUS_DELETED: 'CHAT_CONSTANT/MESSAGE_STATUS_DELETED',
  MESSAGE_STATUS_INFO: 'CHAT_CONSTANT/MESSAGE_STATUS_INFO',

  ROOM_STATUS_OPEN: 'Open',
  ROOM_STATUS_CLOSED: 'Closed'
});


 })(window);