(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/actions/delete-message.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/actions/delete-message.js');

'use strict';

const {
  services
} = svs.components.chat.chatData;
const CHAT_DELETE_MESSAGE_INIT = 'CHAT/CHAT_DELETE_MESSAGE_INIT';
const CHAT_DELETE_MESSAGE_SUCCESS = 'CHAT/CHAT_DELETE_MESSAGE_SUCCESS';
const CHAT_DELETE_MESSAGE_FAILURE = 'CHAT/CHAT_DELETE_MESSAGE_FAILURE';
const deleteMessage = (messageId, roomId) => dispatch => {
  dispatch({
    type: CHAT_DELETE_MESSAGE_INIT,
    messageId,
    roomId
  });
  services.deleteMessage(messageId, error => {
    if (error) {
      dispatch({
        type: CHAT_DELETE_MESSAGE_FAILURE,
        messageId,
        roomId,
        error
      });
    } else {
      dispatch({
        type: CHAT_DELETE_MESSAGE_SUCCESS,
        messageId,
        roomId
      });
    }
  });
};
setGlobal('svs.components.chat.chatData.actions', {
  CHAT_DELETE_MESSAGE_INIT,
  CHAT_DELETE_MESSAGE_SUCCESS,
  CHAT_DELETE_MESSAGE_FAILURE,
  deleteMessage
});

 })(window);