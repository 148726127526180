(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/services/send-message-to-room.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/services/send-message-to-room.js');

'use strict';

const {
  jupiter
} = svs.core;
const sendMessageToRoom = (message, clientToken, roomId, callback) => {
  jupiter.post({
    path: "/chat/1/rooms/".concat(roomId, "/messages"),
    data: {
      message,
      clientToken: "".concat(clientToken)
    }
  }, res => callback(undefined, {
    id: res.result.messageId,
    createdAt: new Date(res.result.created)
  }), () => callback(new Error('Postning av meddelandet misslyckades')));
};
setGlobal('svs.components.chat.chatData.services.sendMessageToRoom', sendMessageToRoom);

 })(window);