(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/actions/delete-queued-message.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/actions/delete-queued-message.js');
'use strict';

const CHAT_DELETE_QUEUED_MESSAGE = 'CHAT/CHAT_DELETE_QUEUED_MESSAGE';
const deleteQueuedMessage = (localId, roomId) => ({
  type: CHAT_DELETE_QUEUED_MESSAGE,
  localId,
  roomId
});
setGlobal('svs.components.chat.chatData.actions', {
  CHAT_DELETE_QUEUED_MESSAGE,
  deleteQueuedMessage
});

 })(window);