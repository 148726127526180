(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/actions/retry-send-message.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/actions/retry-send-message.js');
'use strict';

const {
  services,
  log,
  actions
} = svs.components.chat.chatData;
const {
  CHAT_SEND_MESSAGE_SUCCESS,
  CHAT_SEND_MESSAGE_FAILURE
} = actions;
const CHAT_SEND_MESSAGE_INIT_RETRY = 'CHAT/CHAT_SEND_MESSAGE_INIT_RETRY';
const retrySendMessage = (roomId, localId) => (dispatch, getState) => {
  const message = getState().Chat.queuedMessages[roomId][localId].message;
  dispatch({
    type: CHAT_SEND_MESSAGE_INIT_RETRY,
    roomId,
    localId
  });
  log.debug('Retry send message');
  services.sendMessageToRoom(message, localId, roomId, (error, result) => {
    log.debug("Retry send message done. Success: ".concat(!error, " messageId: ").concat((result || {}).id));
    if (error) {
      dispatch({
        type: CHAT_SEND_MESSAGE_FAILURE,
        error,
        roomId,
        localId
      });
    } else {
      const {
        createdAt,
        id
      } = result;
      dispatch({
        type: CHAT_SEND_MESSAGE_SUCCESS,
        messageId: id,
        localId,
        createdAt,
        roomId
      });
    }
  });
};
setGlobal('svs.components.chat.chatData.actions', {
  CHAT_SEND_MESSAGE_INIT_RETRY,
  retrySendMessage
});

 })(window);