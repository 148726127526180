(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/chat/chat-data/assets/javascripts/helpers/get-message-status.js') >= 0) return;  svs.modules.push('/components/chat/chat-data/assets/javascripts/helpers/get-message-status.js');

'use strict';

const {
  MESSAGE_STATUS_SEND_PENDING,
  MESSAGE_STATUS_SEND_FAILED,
  MESSAGE_STATUS_SENT,
  MESSAGE_STATUS_DELETE_PENDING,
  MESSAGE_STATUS_DELETE_FAILED,
  MESSAGE_STATUS_DELETED,
  MESSAGE_STATUS_NORMAL,
  MESSAGE_STATUS_EDITED,
  MESSAGE_STATUS_INFO,
  SERVER_MESSAGE_STATUS_CHANGED,
  SERVER_MESSAGE_STATUS_DELETED
} = svs.components.chat.chatData.constants;
function getMessageStatus() {
  let msgData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let localData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let metaData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  if (msgData.isInfo) {
    return MESSAGE_STATUS_INFO;
  } else if (msgData.messageStatus === SERVER_MESSAGE_STATUS_CHANGED) {
    return MESSAGE_STATUS_EDITED;
  } else if (metaData.status === MESSAGE_STATUS_DELETED || msgData.messageStatus === SERVER_MESSAGE_STATUS_DELETED) {
    return MESSAGE_STATUS_DELETED;
  } else if (metaData.status === MESSAGE_STATUS_DELETE_PENDING) {
    return MESSAGE_STATUS_DELETE_PENDING;
  } else if (metaData.status === MESSAGE_STATUS_DELETE_FAILED) {
    return MESSAGE_STATUS_DELETE_FAILED;
  } else if (localData.error) {
    return MESSAGE_STATUS_SEND_FAILED;
  } else if (localData.localId && localData.id) {
    return MESSAGE_STATUS_SENT;
  } else if (localData.localId) {
    return MESSAGE_STATUS_SEND_PENDING;
  }
  return MESSAGE_STATUS_NORMAL;
}
setGlobal('svs.components.chat.chatData.helpers.getMessageStatus', getMessageStatus);

 })(window);